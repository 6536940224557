<template>
  <div class="business-transfer-detail-table-wrapper">
    <el-table class="mt15" border stripe :data="tableData">
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
        min-width="120"
        :resizable="false"
        align="center"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <span>
            {{ scope.row[item.prop] }}
          </span>
        </template>
      </el-table-column>
      <!-- 操作 -->
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <span
            class="page-link-noline"
            v-if="scope.row.handleNum > 0"
            @click="goListPage(scope.row)"
          >
            查看明细
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import { goPage } from '@/utils/pagelist-util'
  export default {
    name: 'BusinessDetailConfirmTable',
    props: {
      tableData: {
        //列表数据
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        columns: [
          // 列表列
          {
            label: '业务模块',
            prop: 'businessTypeName',
            isShow: true,
            checkable: false,
          },

          {
            label: '变更内容（交接人出现在以下人员中）',
            prop: 'transferRole',
            isShow: true,
            checkable: true,
            width: '280px',
          },

          {
            label: '默认转交范围',
            prop: 'transferScope',
            isShow: true,
            checkable: true,
          },
          {
            label: '符合条件的数据量',
            prop: 'handleNum',
            isShow: true,
            checkable: true,
          },
        ],
      }
    },

    methods: {
      goListPage({ businessType, orderIds }) {
        goPage({
          keyIds: orderIds && orderIds.join(','),
          businessType,
          ctx: this,
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .business-transfer-detail-table-wrapper {
    max-width: 90%;
  }
</style>
