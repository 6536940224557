<template>
  <div class="custom-container">
    <el-page-header @back="goBack" v-if="!$route.query.noReturn">
      <template slot="content">
        {{ $route.query.title }}
      </template>
    </el-page-header>
    <!-- 选择交接人员 -->
    <ErpSubTitle title="1、选择交接人员" required></ErpSubTitle>
    <HanderChange ref="handerChange" beforeUserPlaceholder="交接人" afterUserPlaceholder="接收人" :showAfterTab="false"
      @to-user-change="changeToUser" @from-user-change="changeFromUser" :showDropDown="false" :showLabel="false"
      :disabled="isDetail" />
    <!-- 确认交接清单 -->
    <ErpSubTitle title="2、确认交接清单" class="mt20"></ErpSubTitle>
    <BusinessDetailConfirmTable :tableData="confirmData" class="mt20" />
    <!-- 业务设置类数据处理 -->
    <ErpSubTitle title="3、业务设置类数据处理" class="mt20"></ErpSubTitle>
    <p>当交接人出现在以下设置中将同步更改为接收人</p>
    <BusinessDetailSettingTable :tableData="settingTable" class="mt20"></BusinessDetailSettingTable>
    <div v-if="!isDetail" class="footer">
      <el-button type="primary" :loading="loading" @click="transferSubmit">
        开始转交
      </el-button>
    </div>
  </div>
</template>

<script>
  import HanderChange from '@/views/system-manage/audit-transfer/components/hander-change'
  import BusinessDetailConfirmTable from './business-detail-confirm-table'
  import BusinessDetailSettingTable from './business-detail-setting-table'
  import { systemInteractor } from '@/core'
  import { mapGetters } from 'vuex'
  const userFields = [
    'fromUserId',
    'fromUserName',
    'fromUserNameEn',
    'toUserId',
    'toUserName',
    'toUserNameEn',
  ]
  export default {
    name: 'BusinessTransferDetail',

    data() {
      return {
        loading: false,
        form: {
          fromUserId: '', //交接人id
          fromUserName: '', //交接人名称
          fromUserNameEn: '', //交接人英文名称
          toUserId: '', //接收人id
          toUserName: '', //接收人名称
          toUserNameEn: '', //接收人英文名称
        },
        confirmData: [], //确认交接清单列表数据
        settingTable: [], //业务设置类数据处理
        isDetail: false, //是否是详情
      }
    },
    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
      }),
    },
    components: {
      HanderChange,
      BusinessDetailConfirmTable,
      BusinessDetailSettingTable,
    },

    methods: {
      // 交接人
      changeFromUser(user) {
        if (this.isDetail) return
        const fields = userFields.slice(0, 3)
        fields.forEach((userField) => {
          this.form[userField] = user ? user[userField] : ''
        })

        if (!this.form.fromUserId) {
          return this._resetTableData()
        }
        // 获取转让数据
        this.transferQuery(this.form.fromUserId)
      },
      // 接收人
      changeToUser(user) {
        const fields = userFields.slice(3)
        fields.forEach((userField) => {
          this.form[userField] = user ? user[userField] : ''
        })
      },

      // 获取转让数据
      async transferQuery(fromUserId) {
        const list = await systemInteractor.transferQuery(fromUserId)
        this.businessTransferTypes = list
        this._getTableData(list)
      },

      // 详情
      async showTransferDetail(businessId, isDetail) {
        this.isDetail = !!isDetail
        if (!businessId) return
        let detailData = await systemInteractor.getDetail(businessId)
        // 处理人员
        this._handlerUserFields(this.form, detailData)
        // 列表数据
        this._getTableData(detailData.businessTransferTypes)
        // 人员回显
        setTimeout(() => {
          this.$refs.handerChange?.initUsers(this.form)
        }, 200)

      },

      // 人员数据处理
      _handlerUserFields(prevUserObj, nextUserObj) {
        userFields.forEach((field) => {
          prevUserObj[field] = nextUserObj[field]
        })
      },
      // 获取两个列表数据
      _getTableData(list) {
        this._resetTableData()
        if (Array.isArray(list)) {
          list.forEach((item) => {
            this[item.transferType == 1 ? 'confirmData' : 'settingTable'].push(
              item
            )
          })
        }
      },

      // 重置相关数据
      _resetTableData() {
        this.confirmData = []
        this.settingTable = []
      },

      // 开始转让
      async submitTransferData() {
        // 校验人员
        if (!this.$refs.handerChange.userCheck()) return
        // 校验列表数据
        if (this.confirmData.length === 0 && this.settingTable.length === 0) {
          return this.$message.warning('没有需要交接的数据')
        }
        this.loading = true
        const isSuccess = await systemInteractor.transferSubmit(
          this.getTransferSubmitReqData()
        )
        this.loading = false
        if (isSuccess) {
          this.$message.success('业务交接新增成功')
          this.$router.back()
        }
      },

      // 获取转让参数
      getTransferSubmitReqData() {
        const reqData = {
          businessTransferTypes: this.businessTransferTypes,
          tenantId: this.userInfo.tenantId,
          creatorId: this.userInfo.userId,
          creator: this.userInfo.userName,
        }
        this._handlerUserFields(reqData, this.form)
        return reqData
      },

      // 返回
      goBack() {
        this.$router.back()
      },

      // 确认转交
      transferSubmit() {
        this.submitTransferData()
      },
    },

    created() {
      const { isDetail, businessTransferId } = this.$route.query
      this.showTransferDetail(businessTransferId, isDetail)
    },
  }
</script>

<style lang="scss" scoped>
  .mt20 {
    margin-top: 20px;
  }

  .footer {
    margin-top: 15px;
  }
</style>
