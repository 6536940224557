<template>
  <div class="business-transfer-detail-table-wrapper">
    <el-table class="mt15" border stripe :data="tableData">
      <el-table-column
        v-for="(item, index) in columns"
        :key="index"
        :label="item.label"
        show-overflow-tooltip
        :sortable="item.sortable"
        :width="item.width"
        :resizable="false"
        align="center"
        :prop="item.prop"
      >
        <template slot-scope="scope">
          <span>{{ scope.row[item.prop] || '--' }}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  export default {
    name: 'BusinessDetailSettingTable',
    props: {
      tableData: {
        //列表数据
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        columns: [
          // 列表列
          {
            label: '业务模块',
            prop: 'businessTypeName',
            isShow: true,
            checkable: false,
          },

          {
            label: '变更内容（交接人出现在以下人员中）',
            prop: 'transferRole',
            isShow: true,
            checkable: true,
            width: '300px',
          },
        ],
      }
    },

    methods: {},
  }
</script>

<style lang="scss" scoped>
  .business-transfer-detail-table-wrapper {
    width: 520px;
  }
</style>
