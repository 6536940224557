<template>
  <div class="change-wrapper">
    <p v-if="showLabel">变更处理人:</p>
    <UserSelect
      :placeholder="beforeUserPlaceholder"
      :disabled="disabled"
      @user-choose="(user) => chooseUser(user, 'BEFORE')"
      ref="userselect"
      :showTab="showBeforeTab"
      :defaultUser="defaultFromUser"
    />
    <i class="el-icon-right"></i>
    <UserSelect
      :placeholder="afterUserPlaceholder"
      :disabled="disabled"
      @user-choose="(user) => chooseUser(user, 'AFTER')"
      ref="userselect"
      :showTab="showAfterTab"
      class="mr15"
      :defaultUser="defaultToUser"
    />
    <el-dropdown
      split-button
      @click="handleClick"
      @command="handleClick"
      v-if="showDropDown"
    >
      更改所有数据
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="CHECKED">更改勾选数据</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
  <!-- 变更处理人结束 -->
</template>

<script>
  import UserSelect from '@/components/user-select'

  export default {
    name: 'HanderChange',
    props: {
      beforeUserPlaceholder: {
        //默认文本
        type: String,
        default: '当前处理人',
      },
      afterUserPlaceholder: {
        //默认文本
        type: String,
        default: '变更后处理人',
      },
      showDropDown: {
        // 是否展示按钮
        type: Boolean,
        default: true,
      },
      showLabel: {
        //是否展示label
        type: Boolean,
        default: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      showBeforeTab: {
        type: Boolean,
        default: true,
      },
      showAfterTab: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        afterUserId: '', //变更后处理人id
        afterUserName: '', //变更后处理人中文名
        afterUserNameEn: '', //变更后处理人英文
        auditUserId: '', //变更前处理人
        auditUserName: '', // 变更前处理人名称
        transferAllData: false, //是否全部转移
        defaultFromUser: {}, //变更前人员
        defaultToUser: {}, // 变更后人员
      }
    },

    components: {
      UserSelect,
    },

    mounted() {},

    methods: {
      // 选择人员
      chooseUser(user, type) {
        if (type === 'BEFORE') {
          this.auditUserId = user ? user.userId : ''
          this.auditUserName = user ? user.userName : ''
          this.auditUserNameEn = user ? user.englishName : ''
          this.$emit('from-user-change', {
            fromUserName: this.auditUserName,
            fromUserNameEn: this.auditUserNameEn,
            fromUserId: this.auditUserId,
          })
        } else {
          this.afterUserId = user ? user.userId : ''
          this.afterUserName = user ? user.userName : ''
          this.afterUserNameEn = user ? user.englishName : ''
          this.$emit('to-user-change', {
            toUserName: this.afterUserName,
            toUserNameEn: this.afterUserNameEn,
            toUserId: this.afterUserId,
          })
        }
      },

      // 下单菜单点击事件
      handleClick(type) {
        if (!this.userCheck()) return
        this.$emit('user-change', {
          afterUserId: this.afterUserId,
          afterUserName: this.afterUserName,
          afterUserNameEn: this.afterUserNameEn,
          auditUserId: this.afterUserId,
          auditUserName: this.auditUserName,
          transferAllData: type === 'CHECKED' ? false : true,
        })
      },

      // 按钮点击校验
      userCheck() {
        if (!this.auditUserId && !this.afterUserId) {
          this.$message.warning(
            `请选择${this.beforeUserPlaceholder}、请选择${this.afterUserPlaceholder}`
          )
          return false
        }
        if (!this.auditUserId) {
          this.$message.warning(`请选择${this.beforeUserPlaceholder}`)
          return false
        }
        if (!this.afterUserId) {
          this.$message.warning(`请选择${this.afterUserPlaceholder}`)
          return false
        }
        if (this.auditUserId === this.afterUserId) {
          this.$message.warning(
            `${this.beforeUserPlaceholder}和${this.afterUserPlaceholder}不能相同`
          )
          return false
        }
        return true
      },
      initUsers(userForm) {
        this.defaultFromUser = {
          userId: userForm.fromUserId,
          userName: userForm.fromUserName,
          userNameEn: userForm.fromUserNameEn,
        }
        this.defaultToUser = {
          userId: userForm.toUserId,
          userName: userForm.toUserName,
          userNameEn: userForm.toUserNameEn,
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .change-wrapper {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    align-items: center;
    p {
      margin-right: 10px;
    }
    i {
      margin: 0 5px;
    }
  }
  .mr15 {
    margin-right: 15px;
  }
</style>
