import { encode, decode } from 'kits'
export const TYPE_2_PATH = {
  1: '/n-customer-manage/cus-center', //客户列表 DONE
  2: '/product/ProductList/ProductList', //产品列表  DONE
  3: '/client-supplier/customer-center', //"客评中心" DONE
  4: '/order/orderList/orderList', // 销售订单   DONE
  5: '/order/shipmentManage/shipmentManage', //出运委托 DONE
  6: '/purchase/purchaseDemand/purchaseDemand', //"采购需求" DONE
  7: '/purchase/purchaseDemand/purchaseDemand', //"备货需求" DONE
  8: '/purchase/inquiryDemand/inquiryDemand', //"询价需求" DONE
  9: '/purchase/purchaseOrder/purchaseOrder', //"采购订单" DONE
  10: '/purchase/cargoManage/cargoManage', //"验货管理" DONE
  11: '/finance-center/credit-manage/index', //"收款单"
}

export const goPage = ({ businessType, keyIds, ctx }) => {
  let path = TYPE_2_PATH[businessType]
  const query = { keyids: encode(keyIds) }
  let routeData = ctx.$router.resolve({
    path,
    query: _handleExtraQuery(businessType, query),
  })
  window.open(routeData.href, '_blank')
}

export const getKeyIds = (ctx) => {
  // 获取keyids
  let keyids = ctx.$route.query.keyids
  if (keyids) {
    return decode(keyids)
  } else {
    return ''
  }
}

function _handleExtraQuery(pageType, query) {
  // 采购需求
  if (pageType == 6) {
    query.tabsType = 1
  }
  // 备货需求
  if (pageType == 7) {
    query.tabsType = 2
  }
  if (pageType == 1) {
    query.type = 2
  }
  return query
}
